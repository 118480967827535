import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import Cover from 'components/common/cover'
import WideCard from 'components/common/wideCard'
import UseCase from 'components/common/useCase'
import TabCover from 'components/common/tabCover'
import ContentCarousel from 'components/common/contentCarousel'
import hyper from '/static/images/use-case/by-scenario/hyper-speed_files_delivery_hero.webp'
import hyperCard from '/static/images/use-case/by-scenario/hyper-speed_files_delivery_bar.webp'
import { hyperTabs, hyperFeatures } from '/static/data/use-case/hyper-speed.static'

const HyperSpeedFilesDelivery = () => {
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: `Use Case: Hyper-Speed Files Delivery for Global Performance - Mlytics`,
            metaDescription: `Experience high-speed global file delivery with Mlytics’ seamless network. Ensure your applications and large files are delivered instantly with no latency worldwide.`,
            metaUrl: 'www.mlytics.com/use-case/hyper-speed-files-delivery',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section
          style={{
            height: 'calc(100vh - 100px)',
            minHeight: '600px'
          }}
          className="px-9 md:px-[86px] mb-5 lg:max-h-[800px] base:absolute base:left-1/2 base:-translate-x-1/2 base:w-screen base:max-w-[1920px]"
        >
          <Cover
            smallTitle="Hyper-Speed Files Delivery"
            title="Transfer your large files at light speed with no scale limit"
            imgSrc={hyper}
            imgAlt="Hyper Speed Hero Picture"
            paragraphContent="No user too far. No files too big. Mlytics ensures highest availability and download successful rate."
            actionLinkOne="chatbot"
            actionTextOne="Learn more"
          />
        </section>
        <div className="hidden mb-5 lg:max-h-[800px] base:block base:h-[calc(100vh-100px)] base:min-h-[600px]"></div>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <h2 className="h2-block-title text-black-100 text-center">Challenge</h2>
            <ContentCarousel listItem={hyperFeatures} />
          </div>
        </section>
        <section className="py-[60px] px-9 md:px-[86px] flex justify-center">
          <TabCover title="Leveraging Mlytics Platform Powers" tabItems={hyperTabs} />
        </section>
        <section className="py-[60px] px-9 md:px-[86px]">
          <div className="w-full flex flex-col gap-10">
            <WideCard
              imgSrc={hyperCard}
              imgAlt="Fix your chunky file delivery now"
              paragraphTitle="Fix your chunky file delivery now."
              paragraphContent=""
              actionLink="/contact-us"
              actionText="Contact us"
              actionColor="btn-cover-orange"
            />
          </div>
        </section>
        <section className="py-[60px]">
          <UseCase />
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default HyperSpeedFilesDelivery

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
