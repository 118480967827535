import carouselOne from '/static/images/use-case/by-scenario/hyper-speed_files_delivery_feature_1.webp'
import carouselTwo from '/static/images/use-case/by-scenario/hyper-speed_files_delivery_feature_2.webp'
import carouselThree from '/static/images/use-case/by-scenario/hyper-speed_files_delivery_feature_3.webp'
import carouselFour from '/static/images/use-case/by-scenario/hyper-speed_files_delivery_feature_4.webp'
import tabOne from '/static/images/use-case/by-scenario/hyper-speed_files_delivery_scenario_well-architected.webp'
import tabTwo from '/static/images/use-case/by-scenario/hyper-speed_files_delivery_scenario_reliability.webp'
import tabThree from '/static/images/use-case/by-scenario/hyper-speed_files_delivery_scenario_cost_optimization.webp'
import tabFour from '/static/images/use-case/by-scenario/hyper-speed_files_delivery_scenario_visibility.webp'

export const hyperTabs = [
  {
    id: 'architected',
    content: 'Well-architected',
    picture: tabOne,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Mlytics fully integrates AWS principles and designed a well-architected environment to specifically solve big file download challenges.`
  },
  {
    id: 'reliability',
    content: 'Reliability',
    picture: tabTwo,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Mlytics uses AWS Cloudfront as default network but employs other networks too to prevent any performance issues.`
  },
  {
    id: 'optimization',
    content: 'Cost optimization',
    picture: tabThree,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Mlytics’ API allows you to do cost-aware network selection.`
  },
  {
    id: 'visibility',
    content: 'Visibility',
    picture: tabFour,
    coverTitle: '',
    coverContent: '',
    link: '/',
    cta: '',
    note: `Monitoring via Amazon CloudWatch Llogs with Mlytics’ additional log features for a unified operational overview.`
  }
]

export const hyperFeatures = [
  {
    title: 'Origin server changes',
    content: 'The directories of files on the origin server may change over time, which leads to download failure.',
    imageSrc: carouselOne,
    imageAlt: 'Origin server changes'
  },
  {
    title: 'Data center reliability',
    content: 'Origin servers and networks might fail in specific regions.',
    imageSrc: carouselTwo,
    imageAlt: 'Data center reliability'
  },
  {
    title: 'High traffic during major updates',
    content:
      'Peak traffic after major release or updates leads to edge servers lacking cached content and causing origin overload.',
    imageSrc: carouselThree,
    imageAlt: 'High traffic during major updates'
  },
  {
    title: 'Unknown download success rate',
    content: `Without proper orchestration and provisioning, it’s hard to have accurate visibility on real end user experience and success rate.`,
    imageSrc: carouselFour,
    imageAlt: 'Unknown download success rate'
  }
]
